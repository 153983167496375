import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TeamComponent} from './team/team.component';
import {PartnersComponent} from './partners/partners.component';
import {HomeComponent} from './home/home.component';
import {ProductComponent} from './product/product.component';
import {BlogComponent} from './blog/blog.component';
import {AuthGuard} from '../auth.guard.service';

const routes: Routes = [
  {path: 'about-us', component: TeamComponent},
  {path: 'our-partners', component: PartnersComponent},
  {path: 'product', component: ProductComponent},
  {path: 'blog', component: BlogComponent},
  {path: '', component: HomeComponent},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
