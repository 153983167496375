import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TeamComponent} from './team/team.component';
import {PartnersComponent} from './partners/partners.component';
import {HomeComponent} from './home/home.component';
import {FormsModule} from '@angular/forms';
import {ProductComponent} from './product/product.component';
import {BlogComponent} from './blog/blog.component';
import {AuthGuard} from '../auth.guard.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Angulartics2Module} from 'angulartics2';

@NgModule({
  declarations: [
    AppComponent,
    TeamComponent,
    PartnersComponent,
    HomeComponent,
    ProductComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    Angulartics2Module.forRoot()
  ],
  providers: [AuthGuard,
    HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule {
}
