import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  name = '';
  email = '';
  topic = '';
  message = '';

  constructor(private titleService:Title) {
    this.titleService.setTitle('XVision | A.I For Smarter Healthcare');
  }

  ngOnInit() {
    $('#press-carousel').owlCarousel({
      loop: true,
      margin: 3,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      },
      navText: ['<i class=\'fa fa-chevron-left\'></i>', '<i class=\'fa fa-chevron-right\'></i>'],
      dots: false
    });

    $('#partners-carousel').owlCarousel({
      loop: true,
      margin: 3,
      autoplay: true,
      autoplayTimeout: 6000,
      autoplayHoverPause: true,
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      },
      navText: ['<i class=\'fa fa-chevron-left\'></i>', '<i class=\'fa fa-chevron-right\'></i>'],
      dots: false
    });

    $('#customers-carousel').owlCarousel({
      loop: false,
      margin: 3,
      autoplay: false,
      responsive: {
        0: {
          items: 2
        },
        400: {
          items: 3
        },
        600: {
          items: 4
        },
        1000: {
          items: 5
        }
      },
      nav: false,
      dots: false,
    });
  }

  SaveMessage() {
    console.log(this.message);
  }

}
