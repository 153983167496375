import {Injectable} from '@angular/core';
import {
  Router, CanActivate, ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(route.url[0].path);
    if (route.url[0].path === 'blog') {
      document.location.href = 'https://xvision.app/blog/';
    }
    return false;
  }
}
