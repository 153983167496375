import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';

declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'landing-page';
  name: string;
  topic: string;
  message: string;
  email: string;

  otherPages = ['/product', '/our-partners', '/about-us'];

  constructor(public location: Location, public http: HttpClient, angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
    this.location = location;
    angulartics2GoogleAnalytics.startTracking();
  }

  goToBlog() {
    document.location.href = 'https://xvision.app/blog/';
  }

  SaveMessage() {
    const formData = {
      name: this.name,
      email: this.email,
      message: this.message
    };
    this.http.post('https://api.xvision.app/partner', JSON.stringify(formData), {responseType: 'text'})
      .subscribe(
        () => {
          const data = 'Message saved';
          const $form = $('#contact-form');
          $form.html('<div class="alert alert-success">' + data + '</div>');
        },
        (error) => {
          console.log(error);
          console.log('error');
        }
      );
  }

  CheckLocation(location, place) {
    const loc = location.split('?')[0];
    if (loc === place) {
      return true;
    }
    return false;
  }
}
