import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('XVision | Partners & Customers ');
  }

  ngOnInit() {
  }

  BecomePartner() {
    console.log('Become Partner!');
  }

}
