import {Component, DoCheck, OnInit, AfterViewInit, HostListener} from '@angular/core';
import {Title} from '@angular/platform-browser';


declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, DoCheck {

  constructor(private titleService: Title) {
    this.titleService.setTitle('XVision | CheXVision');
    setTimeout(() => {
      console.log('Timeout Resize');
      $('.twentytwenty-container').twentytwenty({
        defaultOffsetPct: 0.9
      });
      this.resize();

    }, 200);
  }

  ngOnInit() {
    console.log('OnInit');
    this.resize();
    $('.twentytwenty-container').twentytwenty({
      defaultOffsetPct: 0.9
    });
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    console.log('size changed.', event);
  }

  resize() {
    window.dispatchEvent(new Event('resize'));
  }

  ngDoCheck() {
    window.dispatchEvent(new Event('resize'));
  }

}
